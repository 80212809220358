<template>
  <section id="scene-wrapper" class="flex flex-col justify-center items-center z-10 relative" @mousemove="">
    <!-- hint -->
    <!-- <div class="fixed z-50 top-0 left-0 bg-gray-800 text-white text-xs p-2">{{screenWidth}}</div> -->


    <Snow />
    <!-- <Audio /> -->
    <div id="scene" ref="scene" data-pointer-events="true">
      <div data-depth="0.2" id="bg-scene-layer">
        <div>
          <div class="image-wrapper">
            <img src="/images/xmas_bg-01.png" alt="" @load="itemLoaded">
          </div>
        </div>
      </div>
      <div data-depth="0.6" id="fore-scene-layer">
        <div class="">
          <div id="bg-hover" v-if="!mobileMessage">
            <ul class="message-dots">
              <li v-for="(item, index) in list" :style="{'top': item.pos[0] +'%', 'left': item.pos[1]+'%' }" :class="activeMessage(index)">
                <span class="hover-dot" @mouseover="hoverIn(index)"></span>
                <div class="ring-effect"></div>
              </li>
            </ul>
          </div>

          <div class="image-wrapper">
            <img src="/images/xmas_fore-01.png" alt="" @load="itemLoaded">
          </div>
        </div>
      </div>

      <div id="title-layer" data-depth="0.4">
        <div class="flex flex-col w-full mt-auto">
          <h1>Seasons Greetings <span class="inline-block">and Thank You!</span></h1>
        </div>
      </div>

      <div id="santa-layer" data-depth="0.8">
        <div>
          <!-- title (start) -->
          
          <!-- title (end) -->
          <!-- message(start) -->
          <div id="message-layer">
            <SwiperMessage :list="list" v-if="mobileMessage" />
           
              <transition-group name="list" tag="div" class="message-wrapper" v-if="!mobileMessage">
                <span v-for="(item, index) in list" :key="item._id" v-if="index == selected">
                  <div class="btn-close" @click="close">
                    <svg viewBox="0 0 365.696 365.696" xmlns="http://www.w3.org/2000/svg"><path d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"/></svg>
                  </div>
                  <div v-html="item.message" class="message-input"></div>
                  <h5>{{ item.name }}</h5>
                </span>
              </transition-group>
           
          </div>
          <!-- message(end) -->
          <div class="santa-wrapper">
            <figure>
              <img src="/images/santa-reindeer.png" alt="" class="santa">
              <div class="logo-placement">
                <img src="/images/logo.png" alt="" class="logo">
              </div>
            </figure>
          </div>
        </div>
      </div>

    </div>



  </section>
</template>

<script>
import Snow from '@/components/home/Snow'
import SwiperMessage from '@/components/home/SwiperMessage'
// // import Audio from '@/components/Audio'

import { messages } from '@/assets/js/messages'
import Parallax from 'parallax-js'


export default {
  name: 'Scape',
  components: {
    Snow,
    SwiperMessage
  },
  data(){
    return {
      selected: null,
      list: messages,
      parallaxInstance: null,
      mobileWidth: 800,
      mobileMessage: false,
      screenWidth: 0,
    }
  },
  methods: {
    hoverIn(index){
      this.selected = index
    },
    close(){
      this.selected = null
    },
    activeMessage(index){
      return this.selected === index ? "active" : ""
    },
    itemLoaded(){
      // console.log('loaded')
      this.$store.dispatch('loaded')
    },

    sceneInit(){
      this.parallaxInstance = new Parallax(this.$refs.scene, {
        onReady: function () {
          
        }
      });
    },
    handlerResize(){
      this.screenWidth = window.innerWidth

      this.sceneInit()

      if(window.innerWidth >= this.mobileWidth){
        this.mobileMessage = false
      }else{
        this.mobileMessage = true
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handlerResize)
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handlerResize);
    })

    this.handlerResize()
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:wght@400;600&display=swap');

#scene-wrapper{
  width: 100%;
  height: 100vh;
  @apply overflow-hidden;
}
#scene{
  @apply fixed top-0 left-0 right-0 bottom-0;
}

#fore-scene-layer{
  .image-wrapper{
    @apply relative;
    &::before{
      content: "";
      background-color: #efefef;
      width: 300%;
      left: -100%;
      top: 93%;
      height: 500px;
      
      @apply absolute inline-block z-10;
    }
  }

  
}
#bg-scene-layer{
  .image-wrapper{
    @apply relative;
    &::before{
      content: "";
      background-color: #b3b3b3;
      width: 300%;
      left: -100%;
      top: 93%;
      height: 500px;
      
      @apply absolute inline-block z-10;
    }
  }
}
#bg-scene-layer, #fore-scene-layer, #santa-layer{
  
  @apply flex flex-col h-full w-full;

  & > div{
    bottom: 15%;
    @apply absolute left-0;
  }
}


#santa-layer{
  @media (min-width: 640px){
    pointer-events: none;
  }
  & > div {
    bottom: 0%;
    height: 90%;
    @apply w-full relative flex flex-col;
  }
}

#title-layer{
  @apply flex flex-col w-full justify-center mt-32;

  

  h1{
    font-family: 'Lobster', cursive;
    font-size: 3rem;
    color: #fff;
    line-height: 1.15;
    @apply mb-2;

    @media (min-width: 1024px) { 
      font-size: 4.5rem;
      line-height: 1;

      .inline-block{
        display: block;
      }
    }
  }
}


#message-layer{
  width: 100%;
  height: 70%;
  z-index: 999;
  @apply relative px-6;
}

.message-wrapper{
  @apply flex flex-col w-full h-full relative;

  .btn-close{
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
    pointer-events: auto;
    
    svg{
      fill: #62bd19;
      @apply w-3 h-3 absolute top-0 right-0 mr-2 mt-2;
    }

    &:hover{
      opacity: 1;
    }
  }
}


.message-wrapper span{
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%) ;
  @apply inline-block bg-white rounded-lg px-6 pt-7 pb-6 shadow-xl absolute bottom-0;

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 47%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -8px;
    margin-bottom: -8px;
  }

  // p{
  //   font-family: 'Open Sans', sans-serif;
  //   font-weight: 300;
  //   font-size: 1.25rem;
  // }
  .message-input{
    @apply text-sm text-left text-gray-700;
  }

  h5{
    @apply font-semibold mt-4 text-sm text-left text-blue-300 pl-5 relative;

    &::before{
      content: "";
      height: 1px;
      top: 50%;
      @apply inline-block bg-blue-300 w-3 mr-1 absolute left-0;
    }
  }
}

.santa-wrapper{
  height: 30%;
  figure{
    height: 100%;
    @apply mx-auto inline-block relative;

    img{
      height: 100%;
    }
  }

  .logo-placement{
    left: 50%;
    transform: translateX(-50%) translateY(110%);

    @apply absolute z-40 h-10 bottom-0;

    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    @media (min-width: 640px){
      transform: translateX(100%);
      @apply right-0 bottom-0;
    }
    
  }
}






.message-dots li{
  cursor: pointer;
  @apply absolute z-20;

  &.active{
    .hover-dot{
      height: 24px;
      width: 24px;
      background-color: #62bd19;
    }

    .ring-effect{
      visibility: hidden;
    }
  }
}
.hover-dot{
  height: 16px;
  width: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  border: 2px solid #62bd19;
  transition: all 0.5s;
  @apply rounded-full inline-block absolute shadow z-30;

}

.ring-effect {
    border: 3px solid #62bd19;
    -webkit-border-radius: 30px;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: pulsate 1.5s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0;
    transition: all 0.3s;
}
@keyframes pulsate {
    0% {transform: translate(-50%, -50%) scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: translate(-50%, -50%) scale(1.2, 1.2); opacity: 0.0;}
}
// #particles-js,
// #bg-scene-layer{
//   pointer-events: none;
// }

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}




</style>