<template>
  <div id="app">
    <LoadScreen v-if="!agent"/>
    <Nav />
    <ScapeIE v-if="agent" />
    <Scape v-else />
    <Sky />
  </div>
</template>

<script>
import LoadScreen from '@/components/LoadScreen'
import Nav from '@/components/Nav.vue'
import Scape from '@/components/city/Scape.vue'
import ScapeIE from '@/components/city/ScapeIE.vue'
import Sky from '@/components/Sky'

export default {
  name: 'App',
  components: {
    LoadScreen,
    Nav,
    Scape,
    ScapeIE,
    Sky
  },
  computed: {
    agent(){
      return this.$browserDetect.isIE
    }
  }
}
</script>

<style lang="scss">
*{
  user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;


  @apply block w-full bg-blue-300;
}

</style>
