<template>
  <nav class="fixed top-0 left-0 flex flex-row w-full">
    <div class="absolute top-0 right-0 z-30 inline-block mt-4 mr-4 flex flex-row" >
      
      <div class="toggle-switch mr-2" :class="$store.state.sky">
        <div class="icon-day" @click="toggleSky('day')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.16 45.16"><path d="M22.58 11.269c-6.237 0-11.311 5.075-11.311 11.312s5.074 11.312 11.311 11.312c6.236 0 11.311-5.074 11.311-11.312S28.816 11.269 22.58 11.269zM22.58 7.944a2.207 2.207 0 01-2.207-2.206V2.207a2.207 2.207 0 114.414 0v3.531a2.207 2.207 0 01-2.207 2.206zM22.58 37.215a2.207 2.207 0 00-2.207 2.207v3.53a2.207 2.207 0 104.414 0v-3.53a2.208 2.208 0 00-2.207-2.207zM32.928 12.231a2.208 2.208 0 010-3.121l2.497-2.497a2.207 2.207 0 113.121 3.121l-2.497 2.497a2.207 2.207 0 01-3.121 0zM12.231 32.93a2.205 2.205 0 00-3.121 0l-2.497 2.496a2.207 2.207 0 003.121 3.121l2.497-2.498a2.204 2.204 0 000-3.119zM37.215 22.58c0-1.219.988-2.207 2.207-2.207h3.531a2.207 2.207 0 110 4.413h-3.531a2.206 2.206 0 01-2.207-2.206zM7.944 22.58a2.207 2.207 0 00-2.207-2.207h-3.53a2.207 2.207 0 100 4.413h3.531a2.206 2.206 0 002.206-2.206zM32.928 32.93a2.208 2.208 0 013.121 0l2.497 2.497a2.205 2.205 0 11-3.121 3.12l-2.497-2.497a2.205 2.205 0 010-3.12zM12.231 12.231a2.207 2.207 0 000-3.121L9.734 6.614a2.207 2.207 0 00-3.121 3.12l2.497 2.497a2.205 2.205 0 003.121 0z"/></svg>
        </div>
        <div class="icon-night" @click="toggleSky('night')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 312.812 312.812"><path d="M305.2 178.159c-3.2-.8-6.4 0-9.2 2-10.4 8.8-22.4 16-35.6 20.8-12.4 4.8-26 7.2-40.4 7.2-32.4 0-62-13.2-83.2-34.4-21.2-21.2-34.4-50.8-34.4-83.2 0-13.6 2.4-26.8 6.4-38.8 4.4-12.8 10.8-24.4 19.2-34.4 3.6-4.4 2.8-10.8-1.6-14.4-2.8-2-6-2.8-9.2-2-34 9.2-63.6 29.6-84.8 56.8-20.4 26.8-32.4 60-32.4 96 0 43.6 17.6 83.2 46.4 112s68 46.4 112 46.4c36.8 0 70.8-12.8 98-34 27.6-21.6 47.6-52.4 56-87.6 1.6-5.6-1.6-11.2-7.2-12.4z"/></svg>
        </div>
      </div>

      <div class="toggle-audio" :class="{'active': audio}" @click="toggleAudio">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480"><path d="M278.944 17.577c-5.568-2.656-12.128-1.952-16.928 1.92L106.368 144.009H32c-17.632 0-32 14.368-32 32v128c0 17.664 14.368 32 32 32h74.368l155.616 124.512A16.158 16.158 0 00272 464.009c2.368 0 4.736-.544 6.944-1.6a15.968 15.968 0 009.056-14.4v-416a16.05 16.05 0 00-9.056-14.432zM368.992 126.857c-6.304-6.208-16.416-6.112-22.624.128-6.208 6.304-6.144 16.416.128 22.656C370.688 173.513 384 205.609 384 240.009s-13.312 66.496-37.504 90.368c-6.272 6.176-6.336 16.32-.128 22.624a15.943 15.943 0 0011.36 4.736c4.064 0 8.128-1.536 11.264-4.64C399.328 323.241 416 283.049 416 240.009s-16.672-83.232-47.008-113.152z"/><path d="M414.144 81.769c-6.304-6.24-16.416-6.176-22.656.096-6.208 6.272-6.144 16.416.096 22.624C427.968 140.553 448 188.681 448 240.009s-20.032 99.424-56.416 135.488c-6.24 6.24-6.304 16.384-.096 22.656 3.168 3.136 7.264 4.704 11.36 4.704 4.064 0 8.16-1.536 11.296-4.64C456.64 356.137 480 299.945 480 240.009s-23.36-116.128-65.856-158.24z"/></svg>
      </div>
    </div>
  </nav>
</template>

<script>
import {Howl} from 'howler';

export default {
  data(){
    return {
      sound: null,
      audio: true,
    }
  },
  methods: {
    toggleSky(sky){
      this.$store.commit('toggleSky', sky)
    },
    audioInit(){
      const _this = this
      this.sound = new Howl({
        src: ['/audio/music.mp3'],
        autoplay: true,
        loop: true,
        volume: 0.5,
        load(){
          // _this.sceneInit()
        }
      });
    },
    toggleAudio(){
      if(this.audio === true){
        this.sound.pause()
        this.audio = false
      }else{
        this.sound.play()
        this.audio = true
      }
    }
  },
  created(){
    this.audioInit()
  },
  mounted(){
    this.sound.play()
    console.log('audio')
  }
}
</script>

<style lang="scss">
nav{
  z-index: 9999;
}
.toggle-switch{
  border: 2px solid #fff;
  @apply flex flex-row justify-between items-center leading-none h-8 w-16 rounded-full p-1;

  & > *{
    @apply inline-block p-1;
  }

  .icon-night, .icon-day{
    opacity: 0.4;
    transition: opacity 0.3s;
    @apply cursor-pointer;

    &:hover{
      opacity: 0.75;
    }
  }

  svg{
    fill: #fff!important;
    @apply h-5 w-5 inline-block;
  }

  .icon-night svg{
    @apply h-4 w-4;
  }


  &.day{
    .icon-day{
      opacity: 1;
    }
  }

  &.night{
    .icon-night{
      opacity: 1;
    }
  }
}

.toggle-audio{
  border: 2px solid #fff;
  padding: .4rem;
  @apply flex flex-col h-8 w-8 leading-none rounded-full justify-center items-center;

  svg{
    fill: #fff!important;
    opacity: 0.4;
    transition: opacity 0.3s;

    @apply h-5 w-5 inline-block;



    &:hover{
      opacity: 0.75;
    }

  }

  &.active{
    svg{
      opacity: 1;
    }
  }
}
</style>