<template>
  <div>
    <transition name="fade">
      <div class="fixed bg-blue-400 top-0 left-0 bottom-0 right-0 flex justify-center items-center" id="load-panel" v-if="!$store.state.appLoaded">
        <!-- <div v-if="$store.state.appLoaded" class="flex w-full h-full justify-center items-center">
          <svg @click="start" class="svg-btn inline-block w-16 h-16 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.617 0 0 114.615 0 256s114.617 256 256 256 256-114.615 256-256S397.383 0 256 0zm88.48 269.57l-128 80a16.008 16.008 0 01-16.238.422A15.994 15.994 0 01192 336V176c0-5.82 3.156-11.172 8.242-13.992a15.957 15.957 0 0116.238.422l128 80c4.676 2.93 7.52 8.055 7.52 13.57s-2.844 10.641-7.52 13.57z"/></svg>
        </div> -->
        <div class="lds-circle"><div></div></div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  data(){
    return {
      show: true
    }
  },
  methods:{
    start(){
      this.show = false
    }
  }
}
</script>

<style scoped>
#load-panel{
  z-index: 999;
}

.svg-btn{
  opacity: 0.65;
  transition: all 0.5s;
}
.svg-btn:hover{
  opacity: 0.9;
  transform: scale(1.35);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}



.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

</style>