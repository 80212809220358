export const messages = [
  {
    _id: 1,
    name: "Rosalind Ng",
    message: "I am proud of how we formed teams without boundaries, innovated and problem solved at speed to support our clients.",
    pos: [60, 11.5]
  },
  {
    _id: 2,
    name: "Charlotte Thng",
    message: "I’m proud of our commitment behind reskilling our people and building a flexible workplace. Being conferred the NTUC May Day award for our exemplary inclusive work practices is a testament. Plus I’m very proud of the 1,156 colleagues who stepped up to the challenge to reskill themselves, boosting their future competitiveness.",
    pos: [50, 15]
  },
  {
    _id: 3,
    name: "Lee Woei Shiuan",
    message: "What I have learnt in this COVID-19 year is that we are all in this together. Mental health needs a great deal of attention. Feeling sad, angry, annoyed, frustrated and scared doesn’t make you a negative person. It makes you human. Asking for help is the first step, don’t suffer in silence. Learn from yesterday, live for today, hope for tomorrow with a New Year in 2021.",
    pos: [57, 22]
  },
  {
    _id: 4,
    name: "Cedric Lizin",
    message: "Thanks a lot to all the Private Banking colleagues! Despite the abnormally difficult year on several fronts (COVID-19, organisational changes, etc.) we managed to deliver a good profitability. You should all be proud. Looking to a better 2021 😊",
    pos: [64, 27.5]
  },
  {
    _id: 5,
    name: "Mike Samson",
    message: "2020 reminded us that banking is still an essential service. Many of us worked through the pandemic, like the front-liners in health care and logistics. May we never forget the role that we play, the clients we serve, and the society we support. Onwards to 2021!",
    pos: [70, 32.5]
  },
  {
    _id: 6,
    name: "Richard Sykes",
    message: "I’m extremely proud of how the team pulled together, navigated a funding crisis, kept the Bank well-funded and all while operating from multiple sites.",
    pos: [82, 36]
  },
  {
    _id: 7,
    name: "Veronica Ng",
    message: "They say when the tide goes out, you see who is swimming naked. Well, when the tsunami hit this year, we all saw who were the strongest swimmers. \n\nOur teams were resilient, flexible, agile and upbeat. Seeing how you continue to perform gives me the confidence that we will get past this! THANK YOU!",
    pos: [86, 27]
  },
  {
    _id: 8,
    name: "Eva Ang",
    message: "From the onset of COVID-19, we didn’t skip a beat and quickly adapted to the evolving challenges to enable business continuity. Our display of resilience and solidary inspires hope and confidence – that we will always have the best interests of employees and clients at the heart in all that we do. Thank you!",
    pos: [50, 8.5]
  },
  {
    _id: 9,
    name: "Sarabjit Anand",
    message: "Thank you very much for your contributions and team work during a difficult year. Have a good break during the holiday period and let’s come back re-charged for 2021.",
    pos: [77, 7.5]
  },
  {
    _id: 10,
    name: "Gaurav Bagga",
    message: "I’m proud of the role the Bank has played in helping the Singapore economy and community through a tough 2020! When the going gets tough, the tough get going!!",
    pos: [73, 62.5]
  },
  {
    _id: 11,
    name: "Michele Wee",
    message: "As 2020 draws to a close, we reflect with pride – the resilience, achievements and fortitude displayed by each and every one in our work family. We did it and are stronger than ever. Wishing you and your loved ones joy, health and wellness over the holidays. Ready to conquer 2021!",
    pos: [58, 66]
  },
  {
    _id: 12,
    name: "Tan Eng Ngee",
    message: "I’m proud that many colleagues had reached out during this difficult period to help the communities. Wishing everyone a joyous and restful break!",
    pos: [65, 74]
  },
  {
    _id: 13,
    name: "Ang Chuan Lim",
    message: "I’m amazed at how we have all stepped up in the face of such massive disruption and continued to deliver for our clients.",
    pos: [77, 71.075]
  },
  {
    _id: 14,
    name: "Patrick Lee",
    message: "I’m especially proud of the selfless acts of compassion and solidarity as we stood by our clients, the community and one another. We are in this together, and will always be. To a better and brighter 2021. Thank you.",
    pos: [74, 77]
  },
  {
    _id: 15,
    name: "Priscilla Soh",
    message: "Have a fun filled albeit unconventional year-end holiday!",
    pos: [88, 80.5]
  },
  {
    _id: 16,
    name: "Vincent Loh",
    message: "I am proud how everyone has maintained their focus on Risk and Control even when working from home in their suits, casuals, pyjamas or swimming gear!",
    pos: [70, 82.5]
  },
  {
    _id: 17,
    name: "Goh Beng Kim",
    message: "You have rose to the challenge, something we all ought to be very proud of, and because of that we can look to 2021 with even more optimism and confidence.",
    pos: [85, 86]
  },
  {
    _id: 18,
    name: "Dwaipayan Sadhu",
    message: "As the saying goes, “Tough times don’t last, tough people do”. 2020 was challenging, but I am immensely proud of our strong spirit of resilience, adaptability and teamwork. I am especially proud of how our frontline warriors have been manning the fort since the beginning, the RMs who have been nimble and going above and beyond for clients, and everyone else for rallying together and displaying commendable resilience. Thank you. Happy holidays and enjoy your well-deserved break!",
    pos: [72, 89]
  },
  {
    _id: 19,
    name: "Sumeet Bhambri",
    message: "I’d like to thank everyone for their efforts through an incredibly challenging 2020.\n\nReflecting back, it’s your resilience and teamwork which has allowed us to come together and continue engaging and serving our clients this year. True to our DNA of forging ahead and getting it done, I feel proud and inspired by the way everyone around me has gone above and beyond to deliver for our clients. \n\nI would like to wish everyone the best of this holiday season, and a very prosperous new year for you and your family.",
    pos: [78, 93]
  },
  {
    _id: 20,
    name: "Freddy Ong",
    message: "2020 has taught us a lot. We are tenacious, resilient and highly adaptable. A year where differences narrowed and common objectives prevailed. Countless heroic actions, many wonderful stories to last a lifetime. Thank you all for showing us the strength of our collective spirit – StanChart SG Boleh!",
    pos: [80, 17.5]
  }
]