<template>
  <div id="ie-wrapper">
    <div id="ie-greetings">
      <div class="flex flex-col w-full mt-auto">
        <h1>Seasons Greetings <span class="inline-block">and Thank You!</span></h1>
      </div>
    </div>


    <div id="ie-messages-wrapper">
      <transition-group name="list" tag="div" class="ie-message-wrapper" v-if="!mobileMessage">
        <span v-for="(item, index) in list" :key="item._id" v-if="index == selected">
          <div class="btn-close" @click="close">
            <svg viewBox="0 0 365.696 365.696" xmlns="http://www.w3.org/2000/svg"><path d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"/></svg>
          </div>
          <div v-html="item.message" class="message-input"></div>
          <h5>&mdash; {{ item.name }}</h5>
        </span>
      </transition-group>
    </div>

    
    <div id="ie-foreground">
      <ul class="message-dots">
        <li v-for="(item, index) in list" :style="{'top': item.pos[0] +'%', 'left': item.pos[1]+'%' }" :class="activeMessage(index)">
          <span class="hover-dot" @mouseover="hoverIn(index)"></span>
          <div class="ring-effect"></div>
        </li>
      </ul>
      <div id="ie-santa-wrapper">
        <div class="ie-santa">
          <img src="/images/santa-reindeer.png" alt="" class="santa">
        </div>
      </div>
      <div>
        <img src="/images/xmas_fore-01.png" alt="" @load="itemLoaded">
      </div>
    </div>
    <div id="ie-background">
      <img src="/images/xmas_bg-01.png" alt="" @load="itemLoaded">
    </div>
    <div id="ie-flatbg">

    </div>
  </div>
</template>

<script>
import { messages } from '@/assets/js/messages'

export default {
  data(){
    return {
      selected: null,
      list: messages,
    }
  },
  methods: {
    itemLoaded(){
      this.$store.dispatch('loaded')
    },

    hoverIn(index){
      this.selected = index
    },
    close(){
      this.selected = null
    },
    activeMessage(index){
      return this.selected === index ? "active" : ""
    },
  },
  mounted(){

  }
}
</script>

<style lang="scss">
#ie-wrapper{
  height: 100vh;
  @apply z-10 absolute top-0 left-0 w-full block overflow-hidden;

  #ie-foreground,
  #ie-background,
  #ie-santa-wrapper,
  #ie-messages-wrapper,
  #ie-messages-hover,
  #ie-flatbg{ 
    bottom: 50px;
    @apply absolute left-0 w-full block;
  }
  #ie-foreground{
    z-index: 5;
  }

  #ie-santa-wrapper{
    bottom: -30px;
    z-index: 7;

    .ie-santa{
      height: 100%;
      width: 100%;
      max-width: 300px;
      @apply inline-block mx-auto;
    }
    img{
      height: 100%;
      width: auto;
      display: table;
    }
  }
  .message-dots{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 20;
    display: block;
  }
  .message-dots li{
    cursor: pointer;
    @apply absolute z-40;

    &.active{
      .hover-dot{
        height: 24px;
        width: 24px;
        background-color: #62bd19;
      }

      .ring-effect{
        visibility: hidden;
      }
    }
  }
  .hover-dot{
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9;
    border: 2px solid #62bd19;
    transition: all 0.5s;
    @apply rounded-full inline-block absolute shadow z-30;

  }

  .ring-effect {
      border: 3px solid #62bd19;
      -webkit-border-radius: 30px;
      height: 24px;
      width: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 15;
      transform: translate(-50%, -50%);
      -webkit-animation: pulsate 1.5s ease-out;
      -webkit-animation-iteration-count: infinite; 
      opacity: 0.0;
      transition: all 0.3s;
  }


  #ie-messages-wrapper{
    pointer-events: none;
    bottom: 240px;
    z-index: 10;
    @media (min-height: 640px){

    }

    
    .ie-message-wrapper span{
      max-width: 500px;
      left: 50%;
      transform: translateX(-50%) ;
      z-index: 15;
      background-color: #fff;
      @apply inline-block rounded-lg px-6 pt-7 pb-6 shadow-xl absolute bottom-0;

      &::after{
        content: '';
        position: absolute;
        bottom: 1px;
        left: 47%;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top-color: #fff;
        border-bottom: 0;
        margin-left: -8px;
        margin-bottom: -8px;
      }

      // p{
      //   font-family: 'Open Sans', sans-serif;
      //   font-weight: 300;
      //   font-size: 1.25rem;
      // }
      .message-input{
        @apply text-sm text-left text-gray-700;
      }

      h5{
        color: #93c5fd;
        @apply font-semibold mt-4 text-sm text-left text-blue-300 relative;

        &::before{
          @apply inline-block bg-blue-300 w-0 mr-0 absolute left-0;
        }
      }

      .btn-close{
        opacity: 0.5;
        transition: opacity 0.3s;
        cursor: pointer;
        pointer-events: auto;
        
        svg{
          fill: #62bd19;
          @apply w-3 h-3 absolute top-0 right-0 mr-2 mt-2;
        }

        &:hover{
          opacity: 1;
        }
      }
    }
  }

  @keyframes pulsate {
      0% {transform: translate(-50%, -50%) scale(0.1, 0.1); opacity: 0.0;}
      50% {opacity: 1.0;}
      100% {transform: translate(-50%, -50%) scale(1.2, 1.2); opacity: 0.0;}
  }
  // #particles-js,
  // #bg-scene-layer{
  //   pointer-events: none;
  // }

  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }

  #ie-greetings{
    @apply block mt-32;

    

    h1{
      font-family: 'Lobster', cursive;
      font-size: 3rem;
      color: #fff;
      line-height: 1.15;
      @apply mb-2;

      @media (min-width: 1024px) { 
        font-size: 4.5rem;
        line-height: 1;

        .inline-block{
          display: block;
        }
      }
    }
  }

  #ie-flatbg{
    bottom: 0;
    height: 400px;
    transform: translateY(316px);
    background-color: #e5e5e5;
  }
}


</style>