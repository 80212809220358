<template>
  <div id="bg-sky" :class="skyFunct">
    <img src="/images/day.png" alt="" class="bg-day">
    <img src="/images/night.png" alt="" class="bg-night">
  </div>
</template>

<script>
export default {
  computed: {
    skyFunct(){
      return this.$store.state.sky
    }
  }
}
</script>

<style lang="scss">

#bg-sky{
  z-index: 1;
  @apply absolute top-0 left-0 right-0 bottom-0 h-full w-full;

  img{
    opacity: 0;
    transition: opacity 1s ease-in-out;
    @apply absolute top-0 left-0 w-full h-full z-10;
  }

  &.day{
    .bg-day{
      opacity: 1;
    }
  }
  &.night{
    .bg-night{
      opacity: 1;
    }
  }
}


</style>